<template>
  <div class="info" @click="$emit('tap')">
    <div class="detail-wrapper">
      <div class="left">
        <div class="row1">
          <div class="name" v-if="personItem.userName">
            <template v-if="!isAnonymous">
              {{ personItem.userName.length > 4 ? personItem.userName.substr(0, 4) + '...' : personItem.userName }}
            </template>
            <template v-else>**</template>
          </div>
          <div class="age" v-if="personItem.age">{{ personItem.age }}岁</div>
          <div class="channel" v-if="personItem.channelImage">
            <img :src="personItem.channelImage" />
          </div>
          <!-- <div class="gender">
            <img src="@/assets/img/female.png" v-if="personItem.gender === 1" />
            <img src="@/assets/img/man.png" v-if="personItem.gender === 0" />
            <img src="@/assets/img/unknown-gender.png" v-if="personItem.gender === null" />
          </div> -->
          <div class="channelName" v-if="personItem.channelName && !personItem.channelImage">{{ personItem.channelName }}</div>
        </div>
        <div class="row2">
          <!-- <div class="location" v-if="personItem.location">{{ personItem.location }}</div> -->
          <div class="work-year" v-if="personItem.workAge !== null && personItem.workAge !== 0">{{ personItem.workAge }}年</div>
          <div class="education" v-if="personItem.education !== null">{{ eduList[personItem.education - 1] }}</div>
          <div class="postion-status" v-if="personItem.workStatus !== null && false">{{ workStatusList[personItem.workStatus] }}</div>
        </div>
        <div class="row3" v-if="personItem.recommendName">{{ moment(parseInt(personItem.time)).format('MM月DD日') }}{{ personItem.recommendName }}推荐</div>
        <div class="row4" v-if="personItem.positionName">职位:{{ personItem.positionName }}</div>
      </div>
      <div class="right">
        <div class="row1">
          <div class="icon-wrapper pass" v-if="personItem.operateStatus === 1">
            <div class="img-wrapper"><img src="@/assets/img/pass-icon.png" /></div>
            <span class="icon-text">通过</span>
          </div>
          <div class="icon-wrapper reject" v-if="personItem.operateStatus === 2">
            <div class="img-wrapper"><img src="@/assets/img/reject-icon.png" /></div>
            <span class="icon-text">拒绝</span>
          </div>
          <div class="icon-wrapper wait" v-if="personItem.operateStatus === 4">
            <div class="img-wrapper"><img src="@/assets/img/wait-icon.png" /></div>
            <span class="icon-text">待定</span>
          </div>
        </div>
        <div v-if="personItem.finishAiInterview !== 0" class="ai-score-wrapper">
          <div class="left-score-wrapper">
            <div class="score-container" v-if="personItem.isShowAI !== 2 && personItem.isL5Position == 1">
              <div class="score-text">L5-AI总分</div>
              <van-rate
                v-if="personItem.l5Star > 0 && personItem.isShowAI && personItem.haveVideoQuestion === 1 && +personItem.scoreConfigId !== 7"
                :value="(aiMarkCfg.allStatus && personItem.l5Star) || 0"
                :size="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                readonly
              />
              <template v-else>
                <div class="model-img" v-if="personItem.isShowAI && personItem.haveVideoQuestion === 1 && +personItem.scoreConfigId !== 7">
                  <img src="@/assets/img/generating.png" />
                  <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
                  <!-- <div v-else>-</div> -->
                </div>
                <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              </template>
            </div>
            <div class="score-container" v-else-if="personItem.isShowAI !== 2">
              <div class="score-text">L4-AI总分</div>
              <van-rate
                v-if="personItem.aiAllScore > 0 && personItem.isAiModel > 0 && personItem.isShowAI && personItem.haveVideoQuestion === 1"
                :value="(aiMarkCfg.allStatus && personItem.aiAllScore) || 0"
                :size="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                readonly
              />
              <template v-else>
                <div class="model-img" v-if="personItem.haveVideoQuestion === 1">
                  <img src="@/assets/img/generating.png" v-if="personItem.isShowAI && personItem.isAiModel > 0" />
                  <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
                  <div v-else>模型训练中</div>
                </div>
                <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              </template>
            </div>
            <div class="score-container" v-if="!isXmzCompany">
              <div class="score-text">L2-AI职业形象</div>
              <van-rate v-if="personItem.aiBeauty && personItem.aiBeauty > 0" :value="(aiMarkCfg.allStatus && personItem.aiBeauty) || 0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              <template v-else>
                <div class="model-img" v-if="personItem.haveVideoQuestion === 1">
                  <!-- <div class="inscoring">生成中</div> -->
                  <img src="@/assets/img/generating.png" />
                </div>
                <!-- <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly /> -->
                <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              </template>
            </div>
            <div class="score-container">
              <div class="score-text">L2-AI表情</div>
              <van-rate v-if="personItem.aiSpeech && personItem.aiSpeech > 0" :value="(aiMarkCfg.allStatus && personItem.aiEmotion) || 0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              <template v-else>
                <div class="model-img" v-if="personItem.haveVideoQuestion === 1">
                  <img src="@/assets/img/generating.png" />
                </div>
                <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              </template>
            </div>
          </div>
          <div class="mid-score-wrapper">
            <div class="score-container" v-if="personItem.isShowAI !== 2">
              <div class="score-text">篇章语义</div>
              <!-- aiData.tdnaScore && aiData.tdnaScore > 0 && aiData.isShowAI && aiData.isAiModel > 0 -->
              <van-rate
                v-if="personItem.tdnaStatus && personItem.tdnaStatus > 0 && personItem.isL5Position && personItem.isL5Position === 1"
                :value="(aiMarkCfg.allStatus && personItem.tdnaStatus) || 0"
                :size="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                readonly
              />
              <van-rate
                v-else-if="personItem.tdnaStatus && personItem.isAiModel > 0 && personItem.isShowAI && personItem.haveVideoQuestion === 1"
                :value="(aiMarkCfg.allStatus && personItem.tdnaStatus) || 0"
                :size="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                readonly
              />
              <template v-else>
                <div class="model-img" v-if="personItem.haveVideoQuestion === 1">
                  <img src="@/assets/img/generating.png" v-if="personItem.isShowAI && personItem.isAiModel > 0" />
                  <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
                  <div v-else>模型训练中</div>
                </div>
                <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              </template>
            </div>
            <div class="score-container">
              <div class="score-text">L2-AI声音</div>
              <van-rate v-if="personItem.aiSpeech && personItem.aiSpeech > 0" :value="(aiMarkCfg.allStatus && personItem.aiSpeech) || 0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              <template v-else>
                <div class="model-img" v-if="personItem.haveVideoQuestion === 1">
                  <img src="@/assets/img/generating.png" />
                </div>
                <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
              </template>
            </div>
            <div class="score-container">
              <div class="score-text">人工打分</div>
              <van-rate
                v-if="personItem.estimateVideoScore && personItem.estimateVideoScore > 0"
                :value="personItem.estimateVideoScore || 0"
                :size="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                allow-half
                readonly
              />
              <van-rate
                v-else-if="personItem.estimateTotalScore && personItem.estimateTotalScore > 0"
                :value="personItem.estimateTotalScore || 0"
                :size="10"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                allow-half
                readonly
              />

              <van-rate v-else :value="0" :size="10" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
            </div>
          </div>
          <!-- <div class="right-score-wrapper"></div> -->
        </div>

        <div v-else class="no-data">
          {{ '候选人尚未完成AI面试' }}
        </div>
      </div>
    </div>
    <!-- <div class="hr"></div> -->
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    personItem: {
      type: Object,
    },
    isAnonymous: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment,
      value: 4,
      value2: 3,
      eduList: ['初中及以下', '高中中专中技', '大专', '本科', '硕士', '博士', 'MBA'],
      workStatusList: ['全职', '兼职', '实习全职', '实习兼职'],
    }
  },
  computed: {
    aiMarkCfg() {
      return this.$store.state.aiMarkCfg
    },
    isXmzCompany() {
      const companyId = this.$store.state.companyId
      return this.$store.state.xmzCompanyId.includes(companyId)
    },
    isDingtalk() {
      return process.env.VUE_APP_ENV === 'dingtalk'
    },
  },
}
</script>
<style lang="scss">
.info {
  width: 100%;
  padding-top: 10px;
  margin-bottom: 10px;
  // .hr {
  //   margin: 10px auto 0 auto;
  //   background-color: #dddddd;
  //   width: 90%;
  //   height: 1px;
  //   min-height: 1px;
  //   z-index: 1000;
  // }
  .detail-wrapper {
    // width: 100%;
    display: flex;
    margin: 0 20px;
    padding: 0 0px 10px 0px;
    border-bottom: 1px solid #dddddd;
    .left {
      width: 180px;
      // padding-left: 20px;
      .row1 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 14px;

        .name {
          font-size: 0.4rem;
          font-weight: 600;
        }

        .age {
          font-size: 0.37rem;
          margin-left: 0.2rem;
          color: #333333;
        }

        .channel {
          margin-left: 0.2rem;
          width: 0.4rem;
          height: 0.4rem;

          img {
            border-radius: 50%;
            width: 100%;
          }
        }

        .channelName {
          font-size: 0.4rem;
          margin-left: 0.2rem;
          color: #333333;
        }

        .gender {
          margin-left: 0.2rem;
          width: 0.3rem;
          img {
            width: 100%;
          }
        }
      }

      .row2 {
        //line-height: 1;
        font-size: 14px;
        display: flex;
        color: #b3b3b3;
        flex-wrap: wrap;
        div:not(:first-of-type):before {
          content: '|';
          padding: 0 4px;
        }
      }

      // .row2 {
      //   line-height: 1;
      //   font-size: 14px;
      //   margin-top: 5px;
      //   display: flex;
      //   color: #333333;
      //   flex-wrap: wrap;
      //   .position-name {
      //     max-width: 120px;
      //     overflow: hidden;
      //     white-space: nowrap;
      //     text-overflow: ellipsis;
      //   }
      //   div:not(:first-of-type):before {
      //     content: '|';
      //     padding: 0 0.2rem;
      //   }
      // }
      .row3 {
        //line-height: 1;
        font-size: 14px;
        margin-top: 4px;
        color: #b3b3b3;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .row4 {
        //line-height: 1;
        font-size: 14px;
        margin-top: 4px;
        color: #b3b3b3;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .right {
      flex: 1;
      // padding-right: 20px;
      padding-left: 10px;

      .row1 {
        margin-bottom: 14px;
        height: 21px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .icon-wrapper {
          display: flex;
          align-items: center;
          width: 56px;
          height: 20px;
          border-radius: 10px;

          .img-wrapper {
            width: 20px;
            height: 20px;
            margin-left: -1px;
            margin-top: 1px;
            img {
              width: 100%;
            }
          }
          i {
            margin-left: -2px;
          }
          .icon-text {
            margin-left: 4px;
          }
        }
        .pass {
          color: #1890ff;
          border: 1px solid #1890ff;
        }
        .reject {
          color: #f04d4d;
          border: 1px solid #f04d4d;
        }
        .wait {
          color: #f7b500;
          border: 1px solid #f7b500;
        }
      }

      .no-data {
        width: 100%;
        align-self: center;
        text-align: center;
        font-size: 13px;
        color: #aaaaaa;
      }
      .ai-score-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .score-container {
          .model-img {
            color: #aaaaaa;
            img {
              width: 45px;
            }
          }
          .score-text {
            font-size: 14px;
            color: #aaaaaa;
            height: 16px;
            line-height: 16px;
            margin-bottom: 2px;
            // text-align: center;
            white-space: nowrap;
          }
          .van-rate__item {
            padding-right: 0px;
          }
        }
        .score-container:first-child {
          margin-bottom: 2px;
        }
        .left-score-wrapper {
          margin-right: 5px;
        }
      }
    }
  }

  .aiData-wrapper {
    width: 90%;
    margin: 0.5rem auto 0 auto;
    background-color: #fafafa;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    color: #999999;
    font-size: 0.32rem;
    .aiData-item {
      width: 50%;
      display: flex;
      justify-content: space-between;
      padding: 0.2rem 0.2rem;
      align-items: center;
      .score {
        .vip-img {
          max-width: 2.2rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
.inscoring {
  color: #daecfe;
}
</style>
